import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ErrorPage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
`;

const NotFoundPage = () => {
    return (
        <ErrorPage>
            <p>404</p>
            <p>Nie ma tego czego szukasz :(</p>
            <Link to="/">STRONA GŁÓWNA</Link>
        </ErrorPage>
    );
};

export default NotFoundPage;
